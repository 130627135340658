<template>
  <div class="profile">
    <div class="container">
      <b-row class="mb-4">
        <b-col md="6">
          <template v-if="$options.components['profile-show-edit-profile']">
            <profile-show-edit-profile :editKey="false" />
          </template>
        </b-col>
        <b-col md="6">
          <div class="d-flex justify-content-between">
            <h4>Cashback Accounts</h4>
          </div>
          <!-- add account section start -->
          <div class="text-center mt-2 add-ac" v-if="bankData.length === 0">
            <div>
              <b-img
                class="w-25 mb-3 mt-3"
                src="img/add_cashback_account.png"
                alt
              ></b-img>
              <h6>Not Added Any Cashback Accounts!</h6>
              <p class="mb-4">
                You Will Not Be Able To Get Cashback
                <br />If You Not Add Cashback Account
              </p>
              <button
                @click="addCashbackDetails"
                class="btn btn-outline-primary mt-4 mb-4"
              >
                Add Account
              </button>
            </div>
          </div>
          <div class="p-4 accounts mt-2" v-else>
            <div class="d-flex justify-content-between">
              <div class="d-flex justify-content-between">
                <div class="d-flex">
                  <div class="img-box p-2">
                    <b-img
                      center
                      height="45"
                      src="img/static_bank_logo.png"
                      class="mr-3"
                    ></b-img>
                  </div>
                  <div class="pl-4">
                    <h6>{{ bankData.name }}</h6>
                    <h6>{{ bankData.bankAccount || bankData.vpa }}</h6>
                  </div>
                </div>
              </div>
              <div class="h6 mb-0 d-flex flex-column">
                <a style="color:#fd7e14, cursor:pointer">
                  <b-icon
                    icon="trash"
                    class="rounded"
                    style="color: #fd7e14; cursor: pointer"
                    @click="deleteBankAccount"
                  ></b-icon>
                </a>
                <a style="cursor: pointer" class="mt-2 view-bank">
                  <b-icon
                    icon="eye"
                    class="rounded"
                    style="color: #272727; cursor: pointer"
                    @click="viewBankDetails"
                  >
                  </b-icon>
                </a>
              </div>
            </div>
            <!-- <div class="d-flex justify-content-between mt-1 ml-5">
              <p class="ml-4 pl-2">{{ bankData.ifsc }}</p>
            </div> -->
          </div>
        </b-col>
        <b-modal
          id="view-account"
          size="md"
          centered
          title="Cashback Details"
          hide-footer
        >
          <b-container class="bv-example-row mb-3">
            <b-row cols="2">
              <b-col>
                <div>
                  <p>Name of the Beneficiary</p>
                  <h5>{{ bankData.name }}</h5>
                </div>
              </b-col>
              <b-col>
                <div v-if="bankData.bankAccount">
                  <p>Account No</p>
                  <h5>{{ bankData.bankAccount }}</h5>
                </div>
                <div v-else>
                  <p>UPI Id</p>
                  <h5>{{ bankData.vpa }}</h5>
                </div>
              </b-col>
            </b-row>
            <b-row cols="2" v-if="bankData.ifsc">
              <b-col>
                <div>
                  <p>IFSC</p>
                  <h5>{{ bankData.ifsc }}</h5>
                </div>
              </b-col>
              <!-- <b-col>Column</b-col> -->
            </b-row>
          </b-container>
        </b-modal>
        <b-modal
          id="add-account"
          size="lg"
          centered
          title="Add Cashback Account"
          hide-footer
          no-close-on-backdrop
        >
          <v-container>
            <b-col md="12">
              <b-form-group id="select-method" v-slot="{ ariaDescribedby }">
                <v-row>
                  <b-col md="6">
                    <b-form-radio
                      v-model="selected"
                      id="upi"
                      :aria-describedby="ariaDescribedby"
                      name="some-radios"
                      value="UPI"
                    >
                      Add UPI Account
                    </b-form-radio>
                  </b-col>
                  <b-col md="6">
                    <b-form-radio
                      v-model="selected"
                      id="bank"
                      :aria-describedby="ariaDescribedby"
                      name="some-radios"
                      value="Bank"
                    >
                      Add Bank Account
                    </b-form-radio>
                  </b-col>
                </v-row>
              </b-form-group>
            </b-col>
            <div v-if="showUpiForm">
              <b-col md="12">
                <v-row>
                  <b-col md="8">
                    <v-row>
                      <v-text-field
                        class="mb-3 mt-2"
                        v-model.trim="upiId"
                        label="Enter UPI ID*"
                      >
                      </v-text-field>
                    </v-row>
                  </b-col>
                  <b-col md="2">
                    <button
                      type="button"
                      id="upi-verify-btn"
                      @click="verifyUpiDetails"
                      class="btn btn-primary ml-3"
                      v-if="!upiVerified"
                    >
                      <span class="button-text pr-5 pl-5">Verify</span>
                    </button>
                    <button
                      type="button"
                      id="upi-verified-btn"
                      class="btn btn-primary ml-3"
                      v-else
                    >
                      <span class="button-text pr-5 pl-5">Verified</span>
                    </button>
                  </b-col>
                </v-row>
                <v-row>
                  <v-text-field
                    class="mb-2"
                    v-model="name"
                    label="Name"
                    readonly
                  ></v-text-field>
                </v-row>
                <div
                  class="d-flex justify-content-center mt-5"
                  v-if="upiVerified"
                >
                  <button
                    @click="cancelUpi"
                    type="button"
                    class="btn btn-outline-primary mr-3 pr-5 pl-5"
                  >
                    <span class="button-text">Cancel</span>
                  </button>
                  <button
                    @click="addUpiDetails"
                    type="button"
                    class="btn btn-primary ml-3"
                  >
                    <span class="button-text pr-5 pl-5">Save</span>
                  </button>
                </div>
              </b-col>
            </div>
            <v-form id="bank-input" v-else>
              <b-col md="12">
                <v-row>
                  <b-col md="6" class="pl-0">
                    <v-text-field
                      class="mb-2"
                      v-model.trim="nameOnBank"
                      label="Name on Bank Account"
                    ></v-text-field>
                  </b-col>
                  <b-col md="6" class="pr-0">
                    <v-text-field
                      class="mb-2"
                      v-model.trim="bankName"
                      label="Bank Name"
                    ></v-text-field>
                  </b-col>
                </v-row>
              </b-col>
              <b-col md="12">
                <v-row>
                  <v-text-field
                    class="mb-2"
                    v-model.trim="ifscCode"
                    label="Enter IFSC code"
                  ></v-text-field>
                </v-row>
              </b-col>
              <b-col md="12">
                <v-row>
                  <b-col md="6" class="pl-0">
                    <v-text-field
                      class="mb-2"
                      type="password"
                      v-model="accountNumber"
                      label="Enter Your Account Number"
                    >
                    </v-text-field>
                  </b-col>
                  <b-col md="6" class="pr-0">
                    <v-text-field
                      class="mb-2"
                      type="password"
                      v-model="reAccountNumber"
                      label="Re-enter Your Account Number"
                    >
                    </v-text-field>
                    <p
                      v-if="!accountNumberMatched"
                      class="account-number-matched"
                    >
                      Account number doesn't match!
                    </p>
                  </b-col>
                </v-row>
              </b-col>
              <b-col md="12">
                <div class="d-flex justify-content-center mt-3">
                  <button
                    @click="$bvModal.hide('add-account')"
                    type="button"
                    class="btn btn-outline-primary mr-3 pr-5 pl-5"
                  >
                    <span class="button-text">Cancel</span>
                  </button>
                  <button
                    @click="updateAccount"
                    type="button"
                    class="btn btn-primary ml-3"
                  >
                    <span class="button-text pr-5 pl-5">Save</span>
                  </button>
                </div>
              </b-col>
            </v-form>
          </v-container>
        </b-modal>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "cashback-accounts",
  data() {
    return {
      selected: "UPI",
      upiVerified: false,
      name: "",
      upiId: "",
      nameOnBank: "",
      bankName: "",
      ifscCode: "",
      accountNumber: null,
      reAccountNumber: null,
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.auth.userInfo,
      bankData: (state) => state.profile.bankData,
    }),
    accountNumberMatched() {
      if (
        this.reAccountNumber !== null &&
        this.accountNumber !== this.reAccountNumber
      ) {
        return false;
      } else {
        return true;
      }
    },
    showUpiForm() {
      if (this.selected === "UPI") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    addCashbackDetails() {
      this.upiVerified = false;
      this.$bvModal.show("add-account");
    },
    cancelUpi() {
      this.upiVerified = false;
      this.name = "";
      this.upiId = "";
      this.$bvModal.hide("add-account");
    },
    verifyUpiDetails() {
      try {
        let payload = {
          vpa: this.upiId,
        };
        this.$store
          .dispatch("profile/verifyUpiDetails", payload)
          .then((res) => {
            console.log(res, "check data");
            this.$swal({
              title: "Success!",
              text: res.data?.data?.message,
              type: "success",
              timer: 900,
            });
            this.upiVerified = true;
            this.name = res.data?.data?.data?.nameAtBank || "";
          });
      } catch (error) {
        this.$swal({
          title: "Opps!!!!!",
          text: error.data?.data?.message,
          type: "warning",
        });
      }
    },
    addUpiDetails() {
      try {
        let payload = {
          beneId: this.userInfo?.customerNo,
          name: this.name,
          email: this.userInfo?.authentication?.email,
          phone: this.userInfo?.authentication?.phone,
          vpa: this.upiId,
          address1: this.userInfo?.addresses[0]?.city,
          city: this.userInfo?.addresses[0]?.city,
          state: this.userInfo?.addresses[0]?.state,
          pincode: this.userInfo?.addresses[0]?.zip,
          _id: this.userInfo?._id,
          tenant: this.userInfo?.tenant,
          category: this.userInfo?.category.toUpperCase(),
        };
        this.$store
          .dispatch("profile/updateAccountDetails", payload)
          .then((res) => {
            this.$swal({
              title: "Success!",
              text: res.data?.data?.message,
              type: "success",
            });
            this.$bvModal.hide("add-account");
            this.name = "";
            this.upiId = "";
            this.getBankAccount();
          });
      } catch (error) {
        this.$swal({
          title: "Opps!!!!!",
          text: error.data?.data?.message,
          type: "warning",
        });
      }
    },
    async updateAccount() {
      try {
        let payload = {
          beneId: this.userInfo?.customerNo,
          name: this.nameOnBank,
          email: this.userInfo?.authentication?.email,
          phone: this.userInfo?.authentication?.phone,
          bankAccount: this.accountNumber,
          ifsc: this.ifscCode.toUpperCase(),
          address1: this.userInfo?.addresses[0]?.city,
          city: this.userInfo?.addresses[0]?.city,
          state: this.userInfo?.addresses[0]?.state,
          pincode: this.userInfo?.addresses[0]?.zip,
          _id: this.userInfo?._id,
          tenant: this.userInfo?.tenant,
          category: this.userInfo?.category.toUpperCase(),
        };
        await this.$store
          .dispatch("profile/updateAccountDetails", payload)
          .then(async (res) => {
            this.$swal({
              title: "Success!",
              text: res?.data?.data?.message,
              type: "success",
            });
            this.$bvModal.hide("add-account");
            await this.getBankAccount();
            this.nameOnBank = "";
            this.bankName = "";
            this.ifscCode = "";
            this.accountNumber = null;
            this.reAccountNumber = null;
          });
      } catch (err) {
        console.log(err.response, "error");
        this.$swal({
          title: "Opps!!!!!",
          text: err?.response?.data?.message,
          type: "warning",
        });
      }
    },
    getBankAccount() {
      this.$store.dispatch("profile/getAccountDetails", this.userInfo._id);
    },
    async deleteBankAccount() {
      this.$bvModal
        .msgBoxConfirm("Do you want to delete your account details?", {
          title: "Please Confirm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$store.dispatch(
              "profile/deleteBankDetails",
              this.userInfo._id
            );
            this.$swal({
              text: "You bank account successfully deleted",
              type: "success",
              title: "Success",
              timer: 900,
            });
          }
        });
    },
    viewBankDetails() {
      this.$bvModal.show("view-account");
    },
  },
  mounted() {
    this.getBankAccount();
  },
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
};
</script>

<style scoped>
#upi-verify-btn {
  background: #fd7e14 !important;
  border-color: #fd7e14 !important;
  border-radius: 12px !important;
}

#upi-verified-btn {
  background: #28a745 !important;
  border-color: #28a745 !important;
  border-radius: 12px !important;
}

::v-deep #add-account___BV_modal_content_ {
  padding-right: 25px;
  padding-left: 25px;
  padding-top: 15px;
}

::v-deep #add-account___BV_modal_title_ {
  color: #fd7e14;
  font-size: 24px;
}

::v-deep .custom-control-label {
  font-size: 18px;
  font-weight: 500;
}

#select-method .custom-radio {
  display: inline-block;
}

.add-ac {
  border: 1px solid #efefef;
  border-radius: 10px;
}

.account-number-matched {
  font-size: 14px;
  margin-top: 5px;
  margin-left: 9px;
  color: red;
}

.view-bank b-icon:hover {
  color: #fd7e14;
}

::v-deep #view-account___BV_modal_title_ {
  color: #fd7e14;
}

::v-deep .bv-example-row .row-cols-2 .col p {
  margin-bottom: 0.5rem;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
}

.accounts {
  border: 1px solid #efefef;
  border-radius: 10px;
  background: #f8f8f8;
}

.img-box {
  border: 1px solid #a2a2a2;
  border-radius: 6px;
}

.is-valid {
  font-size: 14px;
  margin-top: 5px;
  margin-left: 9px;
  color: red;
}
</style>
